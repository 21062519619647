import useUserAgent from "@/hooks/useUserAgent";
import colors from "@/styles/colors";
import Link from "next/link";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white100};
  height: 100vh;
`;

const NumberArea = styled.div<{ isMobile: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: ${({ isMobile }) => (isMobile ? "100px" : "200px")};
  line-height: ${({ isMobile }) => (isMobile ? "119px" : "239px")};
  text-align: center;
  color: ${colors.mainBlue};
`;

const TitleArea = styled.div<{ isMobile: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: ${({ isMobile }) => (isMobile ? "32px" : "56px")};
  line-height: ${({ isMobile }) => (isMobile ? "38px" : "67px")};
  text-align: center;
  color: ${colors.black100};
  margin-bottom: ${({ isMobile }) => (isMobile ? "16px" : "30px")};
`;

const DescriptionArea = styled.div<{ isMobile: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ isMobile }) => (isMobile ? "16px" : "32px")};
  line-height: 140%;
  text-align: center;
  color: ${colors.black100};
`;

const ButtonArea = styled.div<{ isMobile: boolean }>`
  margin-top: ${({ isMobile }) => (isMobile ? "40px" : "60px")};
  width: ${({ isMobile }) => (isMobile ? "140px" : "240px")};
  height: ${({ isMobile }) => (isMobile ? "48px" : "64px")};
`;

const Button = styled.button<{ isMobile: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 32px;
  background-color: ${colors.mainBlue};
  color: ${colors.white100};
  border: 1px solid ${colors.mainBlue};
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ isMobile }) => (isMobile ? "16px" : "24px")};
  line-height: ${({ isMobile }) => (isMobile ? "18px" : "24px")};
  text-align: center;
  letter-spacing: -0.05em;
`;

export default function Custom404() {
  const { isMobile } = useUserAgent();
  return (
    <Container>
      <NumberArea isMobile={isMobile}>404</NumberArea>
      <TitleArea isMobile={isMobile}>오류가 발생했어요</TitleArea>
      <DescriptionArea isMobile={isMobile}>
        웹사이트도 가끔은 닥터가 필요할 때가 있죠 😢
      </DescriptionArea>
      <DescriptionArea isMobile={isMobile}>
        주소가 변경 되었거나 삭제 되어 페이지를 찾을 수 없어요.
      </DescriptionArea>
      <ButtonArea isMobile={isMobile}>
        <Link href="/" passHref>
          <Button isMobile={isMobile}>홈으로 돌아가기</Button>
        </Link>
      </ButtonArea>
    </Container>
  );
}
