import { getBrowserInfo } from "@/utils/getBrowserInfo";
import useIsMounted from "./useIsMounted";

interface UserAgentInfo {
  isMobile: boolean;
  isMyDoctorApp: boolean;
  isWebEnvironment: boolean;
  userId: number;
  browserInfo: string;
}

// 전역 변수로 한 번만 계산된 값을 저장
let cachedUserAgentInfo: UserAgentInfo | null = null;

export default function useUserAgent(): UserAgentInfo {
  const isMounted = useIsMounted();
  if (!isMounted) {
    // SSR 대응
    return {
      isMobile: false,
      isWebEnvironment: false,
      isMyDoctorApp: false,
      userId: NaN,
      browserInfo: "",
    };
  }

  if (!cachedUserAgentInfo) {
    const isMyDoctorApp = navigator.userAgent?.indexOf("MyDoctorApp") !== -1;

    const userAgentInfo = {
      isMobile: navigator.userAgent?.indexOf("Mobi") !== -1,
      isMyDoctorApp: isMyDoctorApp,
      userId: Number(navigator.userAgent?.split("userId=")[1]?.split(" ")[0]),
      browserInfo: getBrowserInfo(),
      isWebEnvironment: !isMyDoctorApp,
    };

    if (userAgentInfo.isMyDoctorApp) {
      userAgentInfo.isMobile = true;
      userAgentInfo.browserInfo = "";
    }

    cachedUserAgentInfo = userAgentInfo;
  }

  return { ...cachedUserAgentInfo };
}
